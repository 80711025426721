var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { Microphone } from '@mozartec/capacitor-microphone';
var requestCameraPermission = function () { return __awaiter(void 0, void 0, void 0, function () {
    var camera;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Camera.requestPermissions()];
            case 1:
                camera = _a.sent();
                return [2 /*return*/, camera.camera === 'granted'];
        }
    });
}); };
var requestMicrophonePermission = function () { return __awaiter(void 0, void 0, void 0, function () {
    var micro;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Microphone.requestPermissions()];
            case 1:
                micro = _a.sent();
                return [2 /*return*/, micro.microphone === 'granted'];
        }
    });
}); };
var requestFileSystemPermission = function () { return __awaiter(void 0, void 0, void 0, function () {
    var filesystem;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Filesystem.requestPermissions()];
            case 1:
                filesystem = _a.sent();
                return [2 /*return*/, filesystem.publicStorage === 'granted'];
        }
    });
}); };
var requestPermissions = function (devices, platform) { return __awaiter(void 0, void 0, void 0, function () {
    var permissionGranted, permission, permission, permission;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                permissionGranted = true;
                if (!devices.camera) return [3 /*break*/, 2];
                return [4 /*yield*/, requestCameraPermission()];
            case 1:
                permission = _a.sent();
                if (permission == false)
                    permissionGranted = false;
                _a.label = 2;
            case 2:
                if (!devices.microphone) return [3 /*break*/, 4];
                return [4 /*yield*/, requestMicrophonePermission()];
            case 3:
                permission = _a.sent();
                if (permission == false)
                    permissionGranted = false;
                _a.label = 4;
            case 4:
                if (!(devices.screen && platform == 'android')) return [3 /*break*/, 6];
                return [4 /*yield*/, requestFileSystemPermission()];
            case 5:
                permission = _a.sent();
                if (permission == false)
                    permissionGranted = false;
                _a.label = 6;
            case 6: return [2 /*return*/, permissionGranted];
        }
    });
}); };
function getUserCameraData() {
    return __awaiter(this, void 0, void 0, function () {
        var photo, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Camera.getPhoto({
                            resultType: CameraResultType.Uri,
                            allowEditing: false,
                            source: CameraSource.Camera,
                            direction: CameraDirection.Front,
                            quality: 90
                        })];
                case 1:
                    photo = _a.sent();
                    return [2 /*return*/, {
                            error: false,
                            cameraSrc: photo.webPath,
                            cameraDeviceId: 0,
                            cameraDeviceName: 'Front Camera'
                        }];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, {
                            error: true,
                            errorDescription: error_1
                        }];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/*
async function simulateMicrophoneAccess() {
  
    try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        let microVolume = 0
        
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
  
        const simulateSoundDetection = () => {
          for (let i = 0; i < bufferLength; i++) {
            dataArray[i] = Math.floor(Math.random() * 255);
          }
          const averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
          vm.microVolume = (averageVolume / 255) * 500;
        };
  
        // Simulate sound detection every 200ms
        setInterval(simulateSoundDetection, 200);
  
        // Simulate obtaining microphone device ID (Capacitor doesn't support real-time mic device IDs)
        const deviceInfo = await Device.getInfo();
        vm.devices.micro.id = deviceInfo.uuid;  // Simulated device ID
        console.log('Simulated Microphone ID:', vm.devices.micro.id);
  
        // Simulate enumerating audio devices (there is typically only one microphone on mobile devices)
        const devices = await navigator.mediaDevices.enumerateDevices();
        
        devices.forEach(function(device) {
          if (device.kind === "audioinput") {
            if (device.deviceId === vm.devices.micro.id) {
              vm.devices.micro.name = device.label || "Mobile Microphone";
              console.log('Microphone name:', vm.devices.micro.name);
            }
          }
        });
  
        vm.testErrors.micro = false;
        vm.error = false;
      } else {
        throw new Error('Microphone permission denied');
      }
    } catch (error) {
      console.error('Microphone access error:', error);
      vm.error = true;
      vm.testErrors.micro = true;
      vm.showError(vm.$t('wat_focus_connectivity_error_micro'));  // Handle error accordingly
    }
  }
*/
export { requestPermissions, requestCameraPermission, requestMicrophonePermission, requestFileSystemPermission, getUserCameraData };
